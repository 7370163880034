exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-app-tsx": () => import("./../../../src/pages/App.tsx" /* webpackChunkName: "component---src-pages-app-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-customers-protectapet-tsx": () => import("./../../../src/pages/customers/protectapet.tsx" /* webpackChunkName: "component---src-pages-customers-protectapet-tsx" */),
  "component---src-pages-download-tsx": () => import("./../../../src/pages/download.tsx" /* webpackChunkName: "component---src-pages-download-tsx" */),
  "component---src-pages-downloads-top-3-accountingmistakes-tsx": () => import("./../../../src/pages/downloads/top3accountingmistakes.tsx" /* webpackChunkName: "component---src-pages-downloads-top-3-accountingmistakes-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-partner-unleashed-tsx": () => import("./../../../src/pages/partner/unleashed.tsx" /* webpackChunkName: "component---src-pages-partner-unleashed-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-recruitment-tsx": () => import("./../../../src/pages/recruitment.tsx" /* webpackChunkName: "component---src-pages-recruitment-tsx" */),
  "component---src-pages-software-tsx": () => import("./../../../src/pages/software.tsx" /* webpackChunkName: "component---src-pages-software-tsx" */),
  "component---src-pages-support-proactive-plus-tsx": () => import("./../../../src/pages/support/proactive-plus.tsx" /* webpackChunkName: "component---src-pages-support-proactive-plus-tsx" */),
  "component---src-pages-support-proactive-tsx": () => import("./../../../src/pages/support/proactive.tsx" /* webpackChunkName: "component---src-pages-support-proactive-tsx" */),
  "component---src-pages-support-reactive-tsx": () => import("./../../../src/pages/support/reactive.tsx" /* webpackChunkName: "component---src-pages-support-reactive-tsx" */),
  "component---src-pages-support-tsx": () => import("./../../../src/pages/support.tsx" /* webpackChunkName: "component---src-pages-support-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-pages-terms-of-use-tsx": () => import("./../../../src/pages/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-tsx" */),
  "component---src-pages-testimonies-tsx": () => import("./../../../src/pages/testimonies.tsx" /* webpackChunkName: "component---src-pages-testimonies-tsx" */),
  "component---src-pages-what-we-do-a-2-x-tsx": () => import("./../../../src/pages/what-we-do/a2x.tsx" /* webpackChunkName: "component---src-pages-what-we-do-a-2-x-tsx" */),
  "component---src-pages-what-we-do-hubspot-tsx": () => import("./../../../src/pages/what-we-do/hubspot.tsx" /* webpackChunkName: "component---src-pages-what-we-do-hubspot-tsx" */),
  "component---src-pages-what-we-do-inventory-planner-tsx": () => import("./../../../src/pages/what-we-do/inventory-planner.tsx" /* webpackChunkName: "component---src-pages-what-we-do-inventory-planner-tsx" */),
  "component---src-pages-what-we-do-power-bi-tsx": () => import("./../../../src/pages/what-we-do/power-bi.tsx" /* webpackChunkName: "component---src-pages-what-we-do-power-bi-tsx" */),
  "component---src-pages-what-we-do-shopify-tsx": () => import("./../../../src/pages/what-we-do/shopify.tsx" /* webpackChunkName: "component---src-pages-what-we-do-shopify-tsx" */),
  "component---src-pages-what-we-do-tsx": () => import("./../../../src/pages/what-we-do.tsx" /* webpackChunkName: "component---src-pages-what-we-do-tsx" */),
  "component---src-pages-what-we-do-unleashed-tsx": () => import("./../../../src/pages/what-we-do/unleashed.tsx" /* webpackChunkName: "component---src-pages-what-we-do-unleashed-tsx" */),
  "component---src-pages-what-we-do-vend-tsx": () => import("./../../../src/pages/what-we-do/vend.tsx" /* webpackChunkName: "component---src-pages-what-we-do-vend-tsx" */),
  "component---src-pages-what-we-do-xero-tsx": () => import("./../../../src/pages/what-we-do/xero.tsx" /* webpackChunkName: "component---src-pages-what-we-do-xero-tsx" */)
}

